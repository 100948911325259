import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  ChartBarIcon,
  DocumentIcon,
  BookOpenIcon,
  CurrencyDollarIcon,
  ChartPieIcon,
  ScaleIcon,
  GlobeAltIcon,
  ShareIcon,
  UserGroupIcon,
  AcademicCapIcon,
  UserIcon,
  ComputerDesktopIcon,
  BuildingOfficeIcon,
  TruckIcon,
  CodeBracketIcon,
  BookmarkIcon,
} from '@heroicons/react/24/outline';

const Services = () => {
  const items = [
    { icon: ChartBarIcon, label: 'Statistics' },
    { icon: DocumentIcon, label: 'Accounting' },
    { icon: BookOpenIcon, label: 'History' },
    { icon: CurrencyDollarIcon, label: 'Finance' },
    { icon: ChartPieIcon, label: 'Statistical Analysis' },
    { icon: ScaleIcon, label: 'Law' },
    { icon: GlobeAltIcon, label: 'Tourism' },
    { icon: ShareIcon, label: 'Auditing' },
    { icon: AcademicCapIcon, label: 'Psychology' },
    { icon: UserGroupIcon, label: 'Religion' },
    { icon: UserIcon, label: 'Sociology' },
    { icon: ComputerDesktopIcon, label: 'Technology' },
    { icon: BuildingOfficeIcon, label: 'Business' },
    { icon: TruckIcon, label: 'Supply Chain' },
    { icon: CodeBracketIcon, label: 'Coding' },
    { icon: BookmarkIcon, label: 'Theology' },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 8000,
    autoplaySpeed: 0,
    autoplay: true,
    cssEase: 'linear',
    rows: 2,
    slidesPerRow: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesPerRow:2,
        },
      },
    ],
  };

  return (
    <div className="mx-auto mt-5 max-w-7xl px-6 sm:mt-5 lg:px-8">
      <Slider {...settings} className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-4  border border-gray-300 bg-white shadow-md transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:border-indigo-600"
          >
            <item.icon className="w-8 h-8 text-indigo-600 mb-2" />
            <span className="text-sm font-medium">{item.label}</span>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Services;