import React from 'react'

export default function Footer() {
  return (
<footer
        className="mt-12 bg-gray-900 sm:mt-12"
        aria-labelledby="footer-heading"
      >
        <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
          Disclaimer: The services provided by Homeworkhelp.uk can only be used for reference purpose. Homeworkhelp.uk assist the buyer by providing a guideline and the product provided is intended to be used for research or study purposes only.
            &copy; Homeworkhelp.uk All rights reserved.
          </p>
        </div>
      </footer>  )
}
