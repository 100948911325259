import {React,useState} from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';

  import 'react-accessible-accordion/dist/fancy-example.css';
  import faqlogo from "../images/FAQ-3-1536x982.png";

export default function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleAccordionChange = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    const faqs = [
      {
        question: 'How do you guarantee plagiarism free writing?',
        answer: 'Our work process is designed in such a way that our dedicated quality assurance team checks every order using the most authentic plagiarism detection tools including Turnitin to make sure it is 100% plagiarism-free. Our experts carry out extensive research to provide authentic academic writing services to students worldwide. You can also request a plagiarism report to verify that the order is 100% free of plagiarism.',
      },
      {
        question: 'Will the writer work as per my order guidelines?',
        answer: 'Yes, certainly. Once you successfully place your order, we assign the most suitable writer who thoroughly scans your guidelines and creates an outline to make sure that the assignment you get mirrors the guidelines that you shared with us. We don’t usually bother students with unnecessary questions and do research on our own but we will surely contact you if important order guidelines are missing.',
      },
      {
        question: 'How fast can you do my assignment?',
        answer: 'We have competent subject-matter experts on board who can deliver assignments even in 3 hours because your satisfaction is our priority. We do everything in our power to complete your order on time. 99% of our orders are completed and sent to customers before the deadline so that you can request changes if required.',
      },
      {
        question: 'How will you deliver my order?',
        answer: 'Once the expert completes your order, we will send you the file to your registered email address. In case any corrections are required, you can simply reply to that email.',
      },
      {
        question: 'Can I request a revision for my assignment?',
        answer: 'Our writers go the extra mile to make sure that your assignment is just according to your guidelines so that you don’t have to request any changes. However, if you still want the writer to change anything, you can request revision FREE of cost. You can simply send us a message on WhatsApp or e-mail us. You can also contact our 24/7 available live support team to get the latest updates about revision.',
      },
      {
        question: 'Can I talk to my writer?',
        answer: 'Yes, you can. If you have any questions or messages for the writer, simply send us an email or leave a message using the 24/7 live chat option and the writer will respond soon.',
      },
      {
        question: 'Is your service confidential?',
        answer: "All the interactions between the client and the company are kept highly confidential. The conversation between you and the writers is private and never shared with any third-party companies. Similarly, the client's personal data is also kept highly private."
      },
      {
        question: 'How can I trust your company?',
        answer: "Your concerns are not entirely baseless as many clients have been preyed upon by deceitful companies. However, since the funds you deposit will only be released by you after the completion of your project, you can feel safe and trust our services to deliver the quality you deserve. We follow international laws and offer a 100% money-back guarantee within 3 months. Our reputation for quality and reliability speaks for itself."
      }
    ];
  return (
    <div className="flex flex-col md:flex-row items-center justify-center my-8">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <img
          src={faqlogo}
          alt="Placeholder"
          className="w-25 h-auto"
        />
      </div>
      <div className="md:w-1/2 md:pl-8">
        <Accordion
          allowZeroExpanded={true}
          activeIndex={activeIndex}
          onChange={handleAccordionChange}
        >
          {faqs.map((faq, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>{faq.question}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>{faq.answer}</p>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  )
}
