import { UserPlusIcon, DocumentTextIcon, CurrencyDollarIcon, CogIcon, DocumentArrowDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';

const ProcessSteps = () => {
  const steps = [
    { number: '1', icon: UserPlusIcon, label: 'Contact Us' },
    { number: '2', icon: DocumentTextIcon, label: 'Share Details' },
    { number: '3', icon: CurrencyDollarIcon, label: 'Deposit Funds' },
    { number: '4', icon: CogIcon, label: 'Work in Progress' },
    { number: '5', icon: DocumentArrowDownIcon, label: 'Download File' },
    { number: '6', icon: HandThumbUpIcon, label: 'Release Payment' },
  ];

  return (
    <>
      <h1 className='text-center mt-5 mb-5 font-bold text-2xl'>Simple & Hassle-Free Assignment Ordering Experience</h1>
      <div className="flex flex-col sm:flex-row items-center justify-between bg-white p-4 rounded-lg shadow-md">
        {steps.map((step) => (
          <div key={step.number} className="flex flex-col items-center mb-4 sm:mb-0">
            <div className="flex flex-col items-center justify-center w-12 h-12 rounded-full bg-blue-500 text-white text-xs font-semibold mb-2">
              <span>Step</span>
              <span>{step.number}</span>
            </div>
            <step.icon className="w-5 h-5 text-green-500 mb-1" />
            <div className="text-center text-xs text-gray-600">{step.label}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProcessSteps;