import React from 'react'
import userIcon from "../images/user (4).png"
import { StarIcon } from '@heroicons/react/20/solid'

const featuredTestimonial = {
  body: "I have a strong passion for helping students excel in their machine learning assignments. With my expertise and guidance, I assist them in grasping complex concepts and developing innovative solutions.",
  author: {
    name: "Jacob deGrom",
    handle: "Phd in machine learning",
    imageUrl: userIcon,
    rating: 5,
  },
};

const testimonials = [
  [
    [
      {
        body: "As a seasoned coding instructor, I take pride in empowering students to become proficient programmers. I provide tailored assistance and mentorship to help them tackle challenging coding assignments with confidence.",
        author: {
          name: "Yu Chen Liang",
          handle: "Phd in Computer Science",
          imageUrl: userIcon,
          rating: 4.5,
        },
      },
    ],
    [
      {
        body: "I specialize in guiding students through the intricacies of English literature. My goal is to help them craft compelling essays that showcase their critical thinking skills and mastery of the subject matter.",
        author: {
          name: "Sophie Johnson",
          handle: "MSc in English Literature",
          imageUrl: userIcon,
          rating: 5,
        },
      },
    ],
  ],
  [
    [
      {
        body: "As a business administration expert, I provide comprehensive support to students navigating complex business assignments. I help them develop practical strategies and solutions to real-world business challenges.",
        author: {
          name: "Ankit Patel",
          handle: "MS in Business Administration",
          imageUrl: userIcon,
          rating: 4.5,
        },
      },
    ],
    [
      {
        body: "I am dedicated to assisting students in crafting high-quality marketing reports and assignments. With my industry experience and academic knowledge, I help them create compelling and well-researched content.",
        author: {
          name: "Mohammed Yaseen",
          handle: "BSc in Marketing",
          imageUrl: userIcon,
          rating: 5,
        },
      },
    ],
  ],
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function StarRating({ rating }) {
  return (
    <div className="flex items-center">
      {[...Array(5)].map((_, index) => (
        <StarIcon
          key={index}
          className={classNames(
            index < rating ? 'text-yellow-400' : 'text-gray-300',
            'h-5 w-5'
          )}
          aria-hidden="true"
        />
      ))}
    </div>
  );
}

export default function Testimonials() {
  return (
    <div id="testimonials" className=" isolate sm:mt-56 sm:pt-32">
      {/* Background SVG */}
      <svg
        className="absolute inset-0 -z-10 hidden h-full w-full stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)] sm:block"
        aria-hidden="true"
      >
        {/* ... */}
      </svg>

      <div className="relative">
        {/* Gradient backgrounds */}
        <div
          className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
          aria-hidden="true"
        >
          {/* ... */}
        </div>
        <div
          className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-8 opacity-25 blur-3xl xl:justify-end"
          aria-hidden="true"
        >
          {/* ... */}
        </div>

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl sm:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Meet our team who have helped countless students achieve their dreams
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
            <figure className="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
              <blockquote className="p-12 text-xl font-semibold leading-8 tracking-tight text-gray-900">
                <p>{`"${featuredTestimonial.body}"`}</p>
              </blockquote>
              <figcaption className="flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4">
                <img
                  className="h-10 w-10 flex-none rounded-full bg-gray-50"
                  src={featuredTestimonial.author.imageUrl}
                  alt=""
                />
                <div className="flex-auto">
                  <div className="font-semibold">{featuredTestimonial.author.name}</div>
                  <div className="text-gray-600">{`${featuredTestimonial.author.handle}`}</div>
                  <StarRating rating={featuredTestimonial.author.rating} />
                </div>
              </figcaption>
            </figure>
            {testimonials.map((columnGroup, columnGroupIdx) => (
              <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                {columnGroup.map((column, columnIdx) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) ||
                        (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                        ? "xl:row-span-2"
                        : "xl:row-start-1",
                      "space-y-8"
                    )}
                  >
                    {column.map((testimonial) => (
                      <figure
                        key={testimonial.author.handle}
                        className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                      >
                        <blockquote className="text-gray-900">
                          <p>{`"${testimonial.body}"`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          <img
                            className="h-10 w-10 rounded-full bg-gray-50"
                            src={testimonial.author.imageUrl}
                            alt=""
                          />
                          <div>
                            <div className="font-semibold">{testimonial.author.name}</div>
                            <div className="text-gray-600">{`${testimonial.author.handle}`}</div>
                            <StarRating rating={testimonial.author.rating} />
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}