import {React,useState,useEffect} from 'react'
import { ClockIcon,PhoneIcon,ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/20/solid'
import logo from "../images/logo.png";


function Navbar() {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
        };
    
        handleResize(); // Set initial value
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

  return (
    <header className="fixed inset-x-0 top-0 z-50 bg-white shadow-md">
        <nav className="container mx-auto py-4 px-6" aria-label="Global">
          {isMobile ? (
            <>
              <div className="flex items-center justify-between mb-4">
                <img
                  className="h-20 w-auto"
                  src={logo}
                  alt="Academic World Online"
                />
                <a
                  href="https://wa.me/447754878111"
                  target="\_blank"
                  rel="noopener noreferrer"
                  className="rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  WhatsApp Now
                </a>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex items-center mb-2">
                  <ClockIcon className="h-4 w-4 text-gray-900 mr-2" />
                  <span className="text-sm font-semibold text-gray-900">
                    24/7 Available
                  </span>
                </div>
                <div className="mb-2">
                  <PhoneIcon className="h-4 w-4 text-gray-900 mr-2 inline-block" />
                  <a
                    href="tel:+447754878111"
                    className="text-sm font-semibold text-gray-900"
                  >
                    +44 (775) 4878-111
                  </a>
                </div>
                <a
                  href="https://wa.me/447754878111"
                  target="\_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-sm font-semibold text-blue-500"
                >
                  <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4 mr-2" />
                  <span>Click here to Start Live Chat</span>
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-around">
                <div className="flex items-center">
                  <img
                    className="h-20 w-auto"
                    src={logo}
                    alt="Academic World Online"
                  />
                  <div className="ml-4 flex items-center text-sm font-semibold text-gray-900">
                    <ClockIcon className="h-4 w-4 text-gray-900 mr-2" />
                    <span className="mr-2">24/7 Available</span>
                  </div>
                </div>
                <div className="flex items-center space-x-8">
                  <div className="mr-4">
                    <PhoneIcon className="h-4 w-4 text-gray-900 mr-2 inline-block" />
                    
                    <a
                      href="tel:+447754878111"
                      className="text-sm font-semibold text-gray-900"
                    >
                      Call us at +447754878111
                    </a>
                  </div>
                  <div className="mr-4">
                    <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4 text-blue-500 mr-2 inline-block" />
                    <a
                      href="https://wa.me/447754878111"
                      target="\_blank"
                      rel="noopener noreferrer"
                      className="text-sm font-semibold text-blue-500"
                    >
                      Click here to Start Live Chat
                    </a>
                  </div>
                  <a
                    href="https://wa.me/447754878111"
                    target="\_blank"
                    rel="noopener noreferrer"
                    className="rounded-md bg-green-600 px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    Whatsapp Now
                  </a>
                </div>
              </div>
            </>
          )}
        </nav>
      </header>
  )
}

export default Navbar