import { React, useState, useEffect } from 'react';
import { useForm } from '@formspree/react';
import WhatsappButton from './WhatsappButton';

export default function HeroComponent() {
  const [contactFormBorder, setContactFormBorder] = useState(false);
  const [state, handleSubmit] = useForm('mvoelyqw');
  const [currentWord, setCurrentWord] = useState('');

  useEffect(() => {
    if (contactFormBorder) {
      const timer = setTimeout(() => {
        setContactFormBorder(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [contactFormBorder]);

  useEffect(() => {
    const timer = document.getElementById('timer');
    let timeRemaining = 2 * 60 * 60; // 2 hours in seconds

    const countdown = setInterval(() => {
      const hours = Math.floor(timeRemaining / 3600);
      const minutes = Math.floor((timeRemaining % 3600) / 60);
      const seconds = timeRemaining % 60;

      timer.innerHTML = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      if (timeRemaining <= 0) {
        clearInterval(countdown);
        timer.innerHTML = '00:00:00';
      }

      timeRemaining--;
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    const words = ['assignment', 'dissertation', 'essay', 'exam'];
    let wordIndex = 0;
    let letterIndex = 0;
    let isTyping = true;
    let typingSpeed = 100;
    let erasingSpeed = 50;
    let newWordDelay = 1000;
  
    const typeLetter = () => {
      if (letterIndex <= words[wordIndex].length && isTyping) {
        setCurrentWord(words[wordIndex].slice(0, letterIndex));
        letterIndex++;
        setTimeout(typeLetter, typingSpeed);
      } else if (isTyping) {
        isTyping = false;
        setTimeout(eraseLetter, newWordDelay);
      }
    };
  
    const eraseLetter = () => {
      if (letterIndex >= 0 && !isTyping) {
        setCurrentWord(words[wordIndex].slice(0, letterIndex));
        letterIndex--;
        setTimeout(eraseLetter, erasingSpeed);
      } else if (!isTyping) {
        isTyping = true;
        wordIndex = (wordIndex + 1) % words.length;
        setTimeout(typeLetter, typingSpeed);
      }
    };
  
    typeLetter();
  
    return () => {
      setCurrentWord('');
    };
  }, []);

  if (state.succeeded) {
    return <p>Thanks for submitting the form!</p>;
  }

  return (
    <div>
      <style>
        {`
          @keyframes typing {
            0% {
              width: 0;
            }
            100% {
              width: 100%;
            }
          }

          @keyframes blink-caret {
            from, to {
              border-color: transparent;
            }
            50% {
              border-color: orange;
            }
          }
        `}
      </style>
      <div className="mx-auto max-w-7xl px-6 py-24 sm: mt-20 py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
          <h1 className="mt-10 max-w-lg text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Get the{' '}
            <span
              className="bg-green-600 text-white px-2 overflow-hidden whitespace-nowrap border-r-4 border-orange-500 animate-typing animate-blink-caret"
              style={{ width: currentWord.length + 'ch' }}
            >
              {currentWord}
            </span><>{' '}
            <br/>
            help you need to succeed </>
          </h1>
      <p className="mt-6 text-lg leading-8 text-gray-600">
        We provide high-quality assistance with all types of assignments
        and assessments. Our expert team is here to help you achieve your
        academic goals while maintaining complete confidentiality.
      </p>
      <div className="mt-0 bg-white rounded-xl shadow-sm p-6">
        <h3 className="text-2xl font-semibold mb-4 mt-10">
          Get in touch with our experts
        </h3>
        <p className="text-gray-600 mb-10">
          Our team of real people, not AI, will contact you to provide
          personalized assistance.
        </p>
        <p className="text-lg font-semibold text-red-600 mb-2">
          Limited Time Discount...
          <span className="radius-sm bg-red-600 text-white">
            {" "}
            50% OFF
          </span>
        </p>
        <p className="text-sm text-gray-600 mb-4">
          Discount expires in{" "}
          <span className="font-bold text-xl text-red-600	" id="timer">
            02:00:00
          </span>
          !
        </p>
        <div className="flex justify-center mt-10">
          <WhatsappButton name = "Grab the offer now" />
        </div>
      </div>
    </div>
    <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
      <div
        id="contact-form"
        className={`mx-auto max-w-md rounded-xl shadow-lg p-6 ${
          contactFormBorder
            ? "border-2 border-green-600 animate-pulse"
            : ""
        }`}
      >
        <p className="text-black mb-6 font-bold text-2xl">
          Our team of real people, not AI, will work on your assignment.
        </p>
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 gap-y-6"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label htmlFor="phone" className="sr-only">
                Phone no.
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                autoComplete="tel"
                placeholder="Phone no."
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="subject" className="sr-only">
              Subject/Course Code
            </label>
            <input
              id="subject"
              name="subject"
              type="text"
              placeholder="Subject/Course Code"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            />
          </div>

          <div>
  <label htmlFor="deadline" className="sr-only">
    Deadline
  </label>
  <div
    id="deadline-picker"
    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 cursor-pointer"
    onClick={() => document.getElementById('deadline').showPicker()}
  >
    <span className="text-gray-400 px-2">Select deadline</span>
  </div>
  <input
    id="deadline"
    name="deadline"
    type="date"
    className="hidden"
    onChange={(e) => {
      const selectedDate = e.target.value;
      document.getElementById('deadline-picker').innerHTML = selectedDate;
    }}
  />
</div>

          <div>
            <label htmlFor="pages" className="sr-only">
              Pages
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
              <input
                id="pages"
                name="pages"
                type="number"
                min="1"
          
                className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                placeholder="How many pages?"
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <div className="rounded-md px-3 py-1.5 font-medium text-gray-500">
                  250 words
                </div>
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="description" className="sr-only">
              Assignment Description
            </label>
            <textarea
              id="description"
              name="description"
              rows={4}
              placeholder="Assignment Description"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            />
          </div>

          <div>
            <label className="text-sm font-medium leading-6 text-gray-900">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
              />
              <span className="ml-3">
                I agree to receive marketing offers and emails from Homework help uk
              </span>
            </label>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              disabled={state.submitting}
              className="rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Free Assistance
            </button>
          </div>
        </form>
      </div>
    </div>
  </div></div>
  )
}
