import { useState } from "react";
import WhatsAppPopup from "./WhatsAppPopup";
import ProcessFeature from "../components/ProcessFeature";
import Navbar from "../components/Navbar";
import SecondaryFeatures from "../components/SecondaryFeatures";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import IndigoFeature from "../components/IndigoFeature";
import HeroComponent from "../components/HeroComponent";
import whatsappLogo from "../images/social.png";
import Faq from "../components/Faq";
import StatsComponent from "../components/StatsComponent";
import Popup from "../components/Popup";
import Services from "../components/Services";
import WhatsappButton from "../components/WhatsappButton";

const WhatsAppIcon = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <button
        className="fixed bottom-8 right-8 z-50 flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        onClick={togglePopup}
      >
        <img src={whatsappLogo} alt="WhatsApp Logo" className="w-6 h-6" />
      </button>
      {isPopupOpen && <WhatsAppPopup onClose={togglePopup} />}
    </>
  );
};

export default function Home() {
  return (
    <div className="bg-white">
      <WhatsAppIcon />
      {/* Header */}

      <Navbar />

      <Popup />

      {/* Hero section */}
      <main className="mt-5">
        <HeroComponent />

        {/*feature indigo */}
        <IndigoFeature />

        <SecondaryFeatures />


        {/*whatsapp button */}

  
          {/* Process feature section */}
        <ProcessFeature />

        
        <WhatsappButton name = "Get started with 50% OFF" />

         {/* Stats section */}
         <StatsComponent />

         <div className="mt-10 mx-auto text-center">
            <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Subjects we are experts in
            </p>
          </div>

         <Services/>

        {/* Feature section */}

        <WhatsappButton name = "Get a free quote Now"/>

        {/* Testimonial section */}

        <Testimonials />

       
          
          {/* FAQ section */}
        <Faq />
        <WhatsappButton name = "Get started with 50% OFF" />
      </main>
      

      {/* Footer */}
      <Footer />
    </div>
  );
}
