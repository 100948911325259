import React from "react";
import {
  AcademicCapIcon,
  ClockIcon,
  UserGroupIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  ChatBubbleBottomCenterTextIcon,
  CurrencyPoundIcon,
} from "@heroicons/react/20/solid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const secondaryFeatures = [
    {
      name: "Top UK assignment helper",
      description:
        "As the leading assignment assistance provider in the UK, we have a proven track record of helping students achieve their academic goals.",
      icon: AcademicCapIcon,
    },
    {
      name: "Comprehensive assistance",
      description:
        "We can help with any type of assessment, including essays, online exams, proposals, presentations, reports, research papers, team projects, lab reports, dissertations, and coding assignments.",
      icon: DocumentTextIcon,
    },
    {
      name: "Expert team",
      description:
        "Our team consists of private professors, engineers, and creative writers with years of expertise in their respective fields.",
      icon: UserGroupIcon,
    },
    {
      name: "100% AI-free",
      description:
        "We believe in the importance of original work. That's why we never use AI to complete assignments.",
      icon: PencilSquareIcon,
    },
    {
      name: "Plagiarism-free guarantee",
      description:
        "All our work is carefully checked for plagiarism using advanced software. We ensure that every assignment is original and tailored to your specific requirements.",
      icon: ShieldCheckIcon,
    },
    {
      name: "24/7 customer support",
      description:
        "Our dedicated customer support team is available round the clock to assist you with any questions or concerns you may have. We're always here to help!",
      icon: ChatBubbleBottomCenterTextIcon,
    },
    {
      name: "Affordable pricing",
      description:
        "We offer competitive prices without compromising on quality. Our transparent pricing structure ensures that you know exactly what you're paying for.",
      icon: CurrencyPoundIcon,
    },
  
    {
      name: "Confidential and secure",
      description:
        "We take your privacy seriously. All your personal information is kept strictly confidential, and our website uses advanced security measures to protect your data.",
      icon: LockClosedIcon,
    },
    {
      name: "On-time delivery",
      description:
        "We understand the importance of meeting deadlines. That's why we always deliver your assignments on time, even under tight schedules.",
      icon: ClockIcon,
    },
  ];
  
  export default function SecondaryFeatures() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 0, // Change this to 0 to make it move constantly
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    return (
      <div id="features" className="mx-auto mt-12 max-w-7xl px-6 sm:mt-12 lg:px-8">
        
        
        <Slider {...settings} className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          {secondaryFeatures.map((feature) => (
            <div
              key={feature.name}
              className="flex flex-col p-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:border-2 hover:border-indigo-600"
            >
              <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                <feature.icon
                  className="h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>
              <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                <p className="flex-auto">{feature.description}</p>
              </dd>
            </div>
          ))}
        </Slider>
      </div>
    );
  }