import { useState } from 'react';

const WhatsAppPopup = ({ onClose }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://api.whatsapp.com/send?phone=447754878111&text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
      <div className="relative bg-white w-full max-w-md mx-auto rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Send WhatsApp Message</h2>
        <textarea
          className="w-full h-32 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500 resize-none"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
            onClick={handleSend}
          >
            Send
          </button>
          <button
            className="px-4 py-2 ml-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppPopup;