import React from 'react';
import { UserGroupIcon, TrophyIcon, FaceSmileIcon, DocumentIcon } from '@heroicons/react/24/outline';

const StatsComponent = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-5">
      <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex items-center">
        <div className="flex items-center">
          <UserGroupIcon className="w-8 h-8 text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold">40+</p>
            <p className="text-gray-600">Qualified Experts</p>
          </div>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex items-center">
        <div className="flex items-center">
          <TrophyIcon className="w-8 h-8 text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold">2000+</p>
            <p className="text-gray-600">Completed Orders</p>
          </div>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex items-center">
        <div className="flex items-center">
          <FaceSmileIcon className="w-8 h-8 text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold">99.9%</p>
            <p className="text-gray-600">Customer Satisfaction Rate</p>
          </div>
        </div>
      </div>
      <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex items-center">
        <div className="flex items-center">
          <DocumentIcon className="w-8 h-8 text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold">70+</p>
            <p className="text-gray-600">Subjects</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsComponent;